// Import partials from `sass_dir` (defaults to `_sass`)

@import
	"syntax"
;

body {
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
}

pre {
    font-family: monospace, sans-serif;
}

a{
  color:#333;
}

a:hover{
  color:#666;
  text-decoration: none;
}
.mainheading h1.sitetitle {

}
.mainheading h1.posttitle {
  color: #333;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
}

hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 25%;
    border-top: 12px dotted rgba(113, 51, 197,.3);
}

.article-post a {color:inherit; box-shadow:inset 0 -2px 0 rgba(176, 117, 255,1), 0 2px 0 rgba(176, 117, 255,1);}
.article-post a:hover {box-shadow:inset 0 -30px 0 rgba(176, 117, 255,1), 0 2px 0 rgba(176, 117, 255,1);text-decoration:none;}

dl, ol, ul{
    display: inline-block;
}

.navbar-toggleable-md .navbar-nav .nav-link {
    margin-left: 2.5rem;
    color: #7133C5;
  }
.navbar-toggleable-md .navbar-nav .nav-link:hover {
    color: #4C4475;    
}

.section-title span {
    font-size: 1.5rem;
}

ul.category-tags {
	list-style:none;
	padding-left:0;
	margin: 0 0 1.5rem 0;
}

ul.category-tags li {
	display:inline-block;
	font-size:0.9rem;
  padding-top: 0.3rem;
}
ul.category-tags li a {
    background: #7133C5;
    color: #EEE;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 0.93em;
    text-transform: capitalize;    
    box-shadow: 0 6px 10px 0 rgba(169, 169, 169, 0.1);
}
ul.category-tags li a:hover {
	background:rgba(113, 51, 197,.8);
	text-decoration:none;
}

ul.tag-tags {
	list-style:none;
	padding-left:0;
	margin: 0 0 1.5rem 0;
}

ul.tag-tags li {
	display:inline-block;
	font-size:0.9rem;
  padding-top: 0.3rem;
}
ul.tag-tags li a {
    background: lavender;
    color: #111;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 0.93em;
    text-transform: capitalize;    
    box-shadow: 0 6px 10px 0 rgba(169, 169, 169, 0.1);
}
ul.tag-tags li a:hover {
	background:rgba(230,230,250,.7);
	text-decoration:none;
}

.row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.card .row {
    padding-top: 0px;
    padding-bottom: 0px;
}


.listrecent h4.card-text {
    margin-bottom: 20px;
}


.bottompagination span.navigation a {
    background: lavender;
    color: #333;
    padding: 5px;
    border-radius: 3px;
    text-transform: none;
    font-weight: 500;
    letter-spacing: 0;
    min-width: 100px;
    display: inline-block;
}

.bottompagination{
    padding-bottom:2rem;
}

figure{
    display:block;
    text-align:center;
}


table{
  margin-left: auto;
  margin-right: auto;
}

table, th, td {
  text-align:center;
  padding: 10px;
  border-spacing: 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

@media (min-width: 767px) {
    figure.right-align-image{
        float:right; 
        margin-left:20px;
    }
}


@media (min-width: 767px) {
    figure.left-align-image{
        float:left;
        margin-right:20px;
    }
}

figcaption, caption{
    color: grey;
    font-size:75%;
    font-style: italic;
    text-align: center;
}

* {box-sizing: border-box;}

.img-comp-container {
  position: relative;
}

.img-comp-img {
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
}

.img-comp-img img {
  display: block;
  vertical-align: middle;
}

.img-comp-slider {
  position: absolute;
  z-index: 9;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 40px;
  height: 40px;
  background-color: #2196F3;
  opacity: 0.7;
  border-radius: 50%;
}

#leaderboard-ad{
  margin-bottom: 2rem;
  text-align: center;
}


.after-content-ad {
  margin-bottom: 2rem;
}

.in-content-ad {
  margin-bottom: 2rem;
}

.contact-form {
  margin-bottom: 2rem;
}


.sidebar{
    will-change: min-height;
}

.sidebar-section {
    box-shadow:0 0px 10px 0 rgba(0, 0, 0, 0.15);
    text-align: center;
}
.sidebar__inner{
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

.center-ad{
    text-align: center;
}

.sidebar #mc_embed_signup .button {
  background: #7133C5 !important;
}


.listfeaturedtag .thumbnail {
  background-position:50% 50% !important;
}


.card {
  border-radius:4px;
    margin-bottom: 30px;
    box-shadow:0 0px 8px 0 rgba(0, 0, 0, 0.15);
    border:0;
}

.thumbnail {
  background-position:50% 50% !important;
}


.listfeaturedtag .card {
  margin-bottom:2.5rem;
}



.listrelated .card {
  border-radius:2px;
  padding-left:0;
  margin-bottom:15px;
  align-items: center;
  text-align: center;
  width:100%;
  box-shadow:0 0px 8px rgba(0,0,0,.15);
}
.listrelated .thumbnail {
  background-size:cover;
  height:100%;
  display:block;
  background-origin:border-box!important;
  border-top-left-radius:2px;
}
.listrelated .wrapthumbnail {
  height: 10rem;
  flex: 0 0 auto;
  padding: 0px;
}

.listrelated .card-block {    
  padding: 0.5rem;
}

.listrelated .card-title {
  margin-bottom:0px;
}

.listrelated .card {
  height:auto;
}


.beforefooter {
  padding: 0px;
  height: auto;
  margin-top: 0px;
  background: #ffffff;
}

.footer #mc_embed_signup form {
    padding: 0 !important;
}
.footer #mc_embed_signup .button {
    width: 100% !important;
    background: #675bff !important;
    height: auto;
    padding: 6px 20px;
    font-weight: 600;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1);
}
.footer #mc_embed_signup h2 {
    font-weight: 400;
    font-size: 15px;
    color: #999;
    margin-bottom: 1rem;
    line-height: 1.5;
    text-align: center;
}

.footer #mc_embed_signup input {width:100%;}
.footer #mc_embed_signup .mc-field-group {width:100% !important;}
#mc_embed_signup .mc-field-group input {text-indent: 5% !important;}
#mc_embed_signup {font-family:inherit !important;}


.copyright {
  text-align: center;
  padding: 0px;
}

